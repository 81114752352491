@media #{$desk-M}{
  .page-skills {
    .right-side {
      h1 {
        line-height: 50px;
      }
    }
  }
  #cssmenu {display : none !important ;}
  .container {
    width: 90% !important ;
  }
  .flex > .item {
 width: 32% !important;
  }

}

@media #{$desk-S}{

  .content-lr, .lr-dispo-right, .lr-dispo-left {
    display: block !important;

    .text-lr, .block-lr1, .block-lr2 {
      width: 100% !important;
    }

    .img-lr, .block-lr__img {
      margin: auto;
      width: 80% !important;

      img {
        width: 100%;
        padding: 10px !important;
      }
    }
  }
  .page-skills {
    .content-top, .content-middle {
      display: block !important;

      .left-side, .right-side, .content-expertise-domain, .sidebar-skills {
        width: 100% !important;
        margin: auto;
      }
      .left-side{
        max-height: 100%;
      }
      .basic-informations{
        position: relative !important;
        top: 0 !important;
        width: 100% !important;
      }
      .profil{
        margin: auto !important;
      }
    }

  }
  .page-law {
    .description-law {
      max-width: 80% !important;
    }

    .container {
      display: block !important;
      width : 100% !important ;
      .content-left, .content-right {
        width: 90% !important;
        max-height: 100%;
      }

	  table tr{
		display: block !important;
		padding : 30px 0 !important ;
		height : auto !important ;
		td {
		  display: block !important;
		  width : 100% !important ;
		  height : auto !important ;}
	  }
    }
  }
  .section-contact{
    form{
      input[type=text]{
        max-width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 830px) {
  header {
    .infos-header{
      max-height: none !important;
    }
  }
  .flex > .item {
    width: 49% !important;
  }
}

@media #{$phab-L}{
  .contact-fond_lawyers {
    left: 5% !important;
  }
  .block-our-lawyers {
    margin: auto;
  }
  .grid-item {
    width: 48%;
  }

}

@media #{$phab-L, $phab}{
  .section-contact {
    height: 100%;
    min-height: 950px;
    padding-bottom: 8% !important;

    .img-back {
      img {
        min-height: 950px !important;
      }

      &::before {
        min-height: 950px !important;
      }
    }

    .flex {
      display: block !important;

      .text-contact, .form-contact {
        width: 90% !important;
        margin: auto;
        h2 {
          max-width: 90% !important;
          text-align: center;
          margin: auto;
          padding-top: 2%;
        }

        p {
          text-align: center;
        }
      }
    }
  }
}

@media #{$phab}{
  header {
    #logo {
      padding-bottom: 2%;
    }
  }

  footer {
    .wrap {
      display: block;

      .contact-footer, .multi-menu-footer, #menu-footer {
        width: 90%;
        text-align: center;
        margin: auto;
        padding-top: 2%;

        h2 {
          max-width: 100%;
        }

        .menu-menu-pied-de-page-container {
          display: inline-flex;

          #menu-menu-pied-de-page {
            position: relative;
            text-align: center;
          }
        }
        #IC{
          float: none;
          bottom: 0;
          right: 0;
          left: 0;
          position: relative;
          top: 10px;
        }
      }
    }
  }
  .home-page {
    .text-titles {
      max-width: 60%;
      margin: auto;

      h2 {
        padding-bottom: 5%;
		font-size: 26px;
		line-height: 30px;
      }
    }

    .button-contact {
      top: 80% !important;
    }

    .img-back {
      img {
        min-height: 470px;
      }
    }

    .text-inside {
      padding: 20px 20px 120px 20px !important;
      width: 100%;

      h2 {
        max-width: 100% !important;
        text-align: center;
        padding-top: 5%;
      }

    }

    .content-articles{
      article{
        margin-bottom: 15px !important;
      }
    }
    .srch-home{
      form{
        input{
          width: auto !important;
        }
      }
    }

  }
  .page-skills {
    .content-top {


      .main-informations, .subtitle-specialties {
        padding: 25px 15px !important;
      }
    }


  }
  .page-law {
    .title-law {

      top: 45% !important ;
      h1 {
        width: 60%;
        line-height: 60px;
        margin: auto;
      }
    }

    .rs-top {
      top: 50% !important;
    }

    .img-back {
      img {
        min-height: 470px;
      }
    }

    .block-our-lawyers {

      .line-grey-bis {
        right: 0;
      }

      .content-our-lawyers {
        display: block !important;
        height: 100% !important;

        .lawyers {
          margin: auto;
          width: 100% !important;
        }
      }
      .name{
        width: 100% !important;
      }
    }

    .others-text {
      ul {
        li {
          width: auto !important;
          max-width: 100% !important;
          padding-bottom: 2%;
        }
      }
    }
  }
  .news-flexible {
    h3.title-news-flex {
      margin-bottom: 6% !important;
      margin-top: 6% !important;
      width: 80%;
      margin: auto;
    }

    //.content-articles {
    //  width: calc(100% / 2) !important;
    //}
    .content-articles{
      .upw-posts{
        display: block !important;
        article{
          width: 80% !important;
          margin-bottom: 15px !important;
        }
      }
    }
  }
  //.button-flex {
  //  width: 50% !important;
  //}
  .section-contact {
    form {
      input[type=submit] {
        padding: 10px 20px !important;
      }
    }
  }
  .listing-blog {
    .text-titles {
      width: 65% !important;
      line-height: 50px;
      margin: auto;
      top: 40% !important;
    }
    .img-back{
      img {
        height: min-content;
      }
    }

    .search-filter-results{
      padding-top: 5%;
    }

    .grid-item {
      width: 90% !important;
      margin: auto;
      right: 0;
      min-height: 400px !important;
    }
    .rs-top{
      padding-right: 0 !important;
      top: 55% !important;
      right: -15px !important;
    }
  }
  .img-news{
    .cat-more-infos{
      .date-color{
        margin-right: -4px;
      }
      .cat-news{
        margin-left: 0px;
      }
    }
  }
  .grid-item {
    width: 90% !important;
    margin: auto;
    right: 0;
    min-height: 400px !important;
  }
  #breadcrumbs{
    display : none ;
  }
  .section-block-lr{
    .block-lr__text{
      margin: 0 !important;
      .button-flex{
        margin: auto !important;
      }
      .block-lr__wysi{
        padding-bottom: 5% !important;
      }
    }

  }
}

@media screen and (max-width: 690px){
  .contact-fond_office, .contact-fond_lawyers{
    display: none !important;
  }
}

@media #{$mobil}{
  header{
    .infos-header{
      padding-top: 15px;
      .num{
        padding-top: 15px;
      }
      .call-back{
        margin-top: 15px;
      }
    }
  }


  .flex > .item {
    width: calc(99% - 2px) !important;
    height: 260px !important;
  }
  .button-flex {
    max-width: 80% ! important;
  }
  .news-flexible {
    .content-articles {
      width: 100% !important;
    }
  }
  .section-contact {
    min-height: 1200px !important;

    .img-back {
      img {
        min-height: 1200px !important;
      }

      &::before {
        min-height: 1200px !important;
      }
    }

    form {
      max-width: 100% !important;

      .gform_footer {
        width: 90% !important;
        margin: auto !important;
      }
    }
    .text-contact{
      padding-top: 25px;
    }
  }
  .home-page {

    .office-values{
      .img-lr{
        padding-top: 0 !important;
        padding-bottom: 25px;
      }
    }
  }
  .listing-blog {
    .text-titles {
      width: 65% !important;
      line-height: 50px;
      margin: auto;
      top: 35% !important;
	  h1{
		font-size : 40px;
	  }
    }
    .img-back{
      img {
        height: min-content;
		max-height: 590px;

      }
	  &:before {
		max-height: 590px;
	  }
    }

    .search-filter-results{
      padding-top: 5%;
    }

    .rs-top{
      padding-right: 0 !important;
      top: 40% !important;
      right: -15px !important;
    }
  }
  .img-news{
    .cat-more-infos{
      .date-color{
        margin-right: -4px;
      }
      .cat-news{
        margin-left: 0px;
      }
    }
  }
  .picto-more {
    position: absolute;
    bottom: 0 !important;
    left: 0;
    right: 0;
  }
  .section-wysiwyg{
    .wp-block-image{
      margin-top: 8%;
      figure{
        margin-top: 3%;
      }
    }
    .container{
      width: 80% !important;
      h2{
        padding-bottom: 10%;
      }
      h3{
        padding-top: 10%;
      }
    }
  }
}

@media screen and (max-width: 645px) {
  .page-skills {
    .content-top {

      .left-side {
        max-height: 100% !important;
        height: 100%;

        img {
          margin: auto;
        }

        .basic-informations {
          width: 100%;
          bottom: -15px !important;
        }

        .profil {
          display: block;

          > img {
            max-width: 100% !important;
            max-height: 400px!important;
            height: 100%;
            object-fit: cover;
          }

        }
      }

      .right-side {
        display: block !important;
      }
    }
  }
  .page-flexible{
    .section-header .description-flex {
      max-width: 70%;
      padding: 50px 0;
    }
    .title-flex{
      width: calc(100% - 100px);
      margin: auto;
    }
  }
}

@media screen and (max-width: 442px){
  li.sf-field-category {
    li {
      border-right: none !important;
    }
  }
  .listing-blog{
    .text-titles{
      top: 40%!important;
    }
  }
}

@media screen and (max-width: 400px){
  .page-skills {
    .container {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1845px) and (min-width: 1345px) {
  .home-page {
    .contact-fond_lawyers {
      left: -20% !important;
      position: relative;
    }
  }
}
