.page-flexible {
  #breadcrumbs {
    background-color: $greyclear;


    .breadcrumb_last {
      color: #838da2;
    }
  }

  .section-header {
    //min-height: 400px;

    background-color : #f4f4f4 ;
    position: relative;
    .content-top-flex {
      background-color: $greyclear;
      min-height: 100px;
      height: 100%;
      .title-flex {
        h1 {
          color: $bluetypo;
          font-size: 45px;
          font-family: $font-notoSans;
          font-weight: 500;
          text-align: center;
          line-height: 50px;
          max-width: 1400px;
          margin: 0 auto 30px;
        }
      }
    }
     + .description-flex {
      padding-top: 50px;
      font-size: 24px;
      font-family: $font-noto;
      color: $bluetypo;
      text-align: center;
      max-width: 40%;
      margin: 0 auto 40px;
      line-height: 35px;
       @media (max-width: 767px){
         max-width: 80%;
       }

    }

  }

  .section-block-lr {
    padding-bottom: 2%;

    .lr-dispo-left {
      padding-bottom: 2%;
      align-items: center ;

      .block-lr1 {
        order: 0;
        text-align : right ;
      }

      .block-lr2 {
        order: 1;
      }
    }

    .lr-dispo-right {
      padding-bottom: 2%;
      align-items: center ;
      .block-lr1 {
        order: 1;
      }

      .block-lr2 {
        order: 0;
      }
    }

    .block-lr1, .block-lr2 {
      width: 50%;
      align-items: center;
      display: flex;
    }

    .block-lr__img {
      max-width: 100%;
      width: 100%;
      img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        max-width: 625px;
        max-height: 500px;
        width: 100%;
        padding: 40px;
        margin: auto;
        border-radius: 45px;
      }
    }

    .block-lr__text {
      padding: 40px;
      //margin-left: 20%;
      margin: 0 auto;
      h2 {
        line-height: 30px;
        position: relative;
        margin-left: 15px;
        font-size: 25px;
        font-family: $font-noto;
        color: $bluetypo;
        font-weight: 300;
        margin-bottom: 2%;

        &:before {
          height: 100%;
          width: 2px;
          @include bg-gradient();
          content: '';
          position: absolute;
          left: -15px;
        }
      }
      table tr td {padding : 10px;}

      .block-lr__wysi{
        padding-bottom: 2%;
      p {
        font-family: $font-notoSans;
        color: $bluetypo;
        font-size: 15px;
        line-height: 23px;
      }
    }
      .button-flex {
        background-color: $blue;
        display :inline-block;
        padding: 15px;
        border-radius : 5px;

        a {
          color: $white;
          font-family: $font-noto;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .section-wysiwyg {
    padding-top: 3%;
    padding-bottom: 1%;
    &:last-child{
      padding-bottom: 4%;
    }
    h2 {
      font-size: 40px;
      font-family: $font-noto;
      color: $bluetypo;
      font-weight: 300;
      margin-bottom: 2%;
      margin-top: 2%;
      line-height: 40px;
    }

    h3 {
      font-size: 28px;
      font-family: $font-notoSans;
      color: $bluetypo;
      font-weight: 600;
      margin-bottom: 2%;
      margin-top: 2%;
      line-height: 25px;
    }

    p {
      font-family: $font-notoSans;
      color: $bluetypo;
      font-size: 15px;
      line-height: 23px;
    }

    li {
      font-family: $font-notoSans;
      color: $bluetypo;
      font-size: 15px;
      line-height: 25px;
      list-style-type: none;
    }
    form{
      li{
        background: none;
      }
      .gfield_required{
        display: none;
      }
      input[type="submit"], .gform_footer input.button {
        @include bg-gradient();
        color: #FFF;
        border: none;
        -webkit-appearance: none;
        text-transform: uppercase;
        padding: 20px 40px;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        font-family: $font-notoSans;
        margin-left: 25px;
		border-radius: 5px;
      }
      .gform_confirmation_wrapper{
        font-weight: 300;
        font-size: 25px;
        font-family: $font-effra;
        line-height: 30px;
        color: $bluetypo;
      }
      ::-webkit-input-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }

      ::-moz-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }

      :-ms-input-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }

      :-moz-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }
      input, select, .gform_footer{
        margin: auto !important;
        display: flex;
      }
    }
  }

  .section-contact {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
	z-index: 10;
    background-position: center;
    &::before {
      content: ' ';
      background-color: rgba(18, 43, 103, .7);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .container {
      position: relative;
      z-index: 100;
      padding:  50px  0 ;
      .text-contact {
        width: 40%;
        color: $white;

        margin: auto;
        padding-left: 10%;
        margin-top: 4%;

        h2 {
          font-size: 50px;
          font-family: $font-noto;
          font-weight: 400;
          max-width: 55%;
          line-height: 60px;
          padding-bottom: 2%;
        }

        p {
          font-weight: 300;
          font-size: 25px;
          font-family: $font-effra;
          line-height: 30px;
        }
      }

      .form-contact {
        width: 60%;
        padding-top: 4%;
        form {
          max-width: 70%;
          position: relative;

          li {
            background: none;
            a{
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              @include bg-gradient();
            }
          }
          .gfield_label {
            display: none;
          }

          .gfield_error, .validation_message, div.validation_error, .gfield_required, .gfield_error .gfield_label, label {
            color: #C90043;
            padding-top: 0;
            border: none;
          }

          li.gfield_error input[type=text], li.gfield_error input[type=email], li.gfield_error input[type=tel], li.gfield_error input[type=number], li.gfield_error input[type=password], li.gfield_error textarea {
            border: none;
            border-bottom: 1px solid #C90043;
          }
          li.gfield_html{
            color: $white;
            font-family: $font-notoSans;
            font-size: 11px;
          }

          li.gfield.gfield_error {
            background-color: transparent;
            border-top: none;
            border-bottom: none;
          }

          input[type="text"], textarea, select {
            font-family: $font-notoSans !important;
            background-color: transparent;
            border: 1px solid #FFF;
            width: 100%;
            color: #FFF;
            padding: 10px !important;
            -webkit-text-fill-color: #fff;
          }

          input[type="text"] {
            border-bottom: 1px solid $white;
            border-top: none;
            border-right: none;
            border-left: none;
            max-width: 65%;

            &:active {
              &::after {
                @include bg-gradient();
                height: 1px;
                width: 100%;
              }
            }
          }

          .gform_footer {
            width: auto;
            position: absolute;
            right: 0;
            bottom: 0;
			@media screen and(max-width: 1300px){
			  position :relative ;
			}

          }

          input[type="submit"], .gform_footer input.button {
            @include bg-gradient();
            color: #FFF;
            border: none;
            -webkit-appearance: none;
            text-transform: uppercase;
            padding: 20px 40px;
            text-align: center;
            cursor: pointer;
            display: inline-block;
            float: right;
            font-family: $font-notoSans;
            border-radius: 5px;
          }


          ::-webkit-input-placeholder {
            color: #FFF !important;
            font-family: $font-notoSans;
          }

          ::-moz-placeholder {
            color: #FFF !important;
            font-family: $font-notoSans;
          }

          :-ms-input-placeholder {
            color: #FFF !important;
            font-family: $font-notoSans;
          }

          :-moz-placeholder {
            color: #FFF !important;
            font-family: $font-notoSans;
          }


		}
		.gform_confirmation_wrapper{
		  font-weight: 300;
		  font-size: 25px;
		  font-family: $font-effra;
		  line-height: 30px;
		  color: $white ;
		}
      }
    }
  }

  input:-internal-autofill-selected {background : transparent !important ;}

  .section-map {
    padding-bottom: 0;
    .content-map {
      position: relative;
      margin-bottom: -5px;

      iframe {
        width: 100%;
        max-height: 325px;
      }
    }
  }

  .news-flexible {
    padding-top: 2%;
    background-color: $greyclear;

    h3.title-news-flex {

      font-size: 28px;
      font-family: $font-notoSans;
      color: $bluetypo;
      font-weight: 600;
      margin-bottom: 2%;
      margin-top: 2%;
      text-align: center;
      text-transform: uppercase;
      line-height: 30px;
    }

    .wrap {
      padding-bottom: 5%;

      .content-articles {
        width: 100%;

        .img-bloc-plus {
          display: none;
        }

        h3 {
		  color: #132651!important;
		  font-family: "Noto Serif",serif;
		  font-size: 18px;
		  font-weight: 600;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  max-height: 75px;
		  -webkit-line-clamp: 3;
		  -webkit-box-orient: vertical;
		  overflow: hidden;
		  padding: 0;
		  margin: 15px 0;
		  height: 75px;
        }

        .text-bloc-plus {
          //max-width: 75%;

          a {
            color: $bluetypo;
            -webkit-text-fill-color:transparent;
            background-image: -webkit-gradient(linear,right top,left top,from($bluetypo),to($bluetypo));
            -webkit-background-clip: text;
            }


          span {
            font-family: $font-notoSans;
            font-size: 14px;
            color: $bluetypo;
            font-weight: 400;
            //padding-top: 10px;
            display: inline-flex;

            p {
              color: $bluetypo;
              font-weight: 600;
              margin-left: 5px;
            }
          }
        }

		.summary-news {    padding-right: 50px;
		  position: relative;
		  .more-informations-news {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		  }
		}

        article {
          width: calc(94% / 3);
          background-color: $white;
          box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.35);
          -webkit-box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.35);
          -moz-box-shadow: 0 15px 4px -10px rgba(0, 0, 0, 0.35);
          padding: 20px;
          margin: auto 1%;
          .flex{
            justify-content: space-evenly;
          }
          .cat-more-infos{
            display: none;
            span{
              font-family: $font-notoSans;
              display: inline-block;
              color: $white;
            }
            .cat-news {
              @include bg-gradient();
              color: $white;
              padding: 10px;
              margin-left: -4px;
              span.pipe-cat{
                &:last-child{
                  display: none;
                }
              }
            }
            .date-color{
              background-color: $white;
              padding: 0;
              .cat-date {
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                padding: 10px;
                @include bg-gradient();
              }
            }
          }
          a{
            -webkit-webkit-text-fill-color: $bluetypo;
            -moz-webkit-text-fill-color: $bluetypo;
            -ms-webkit-text-fill-color: $bluetypo;
            -o-webkit-text-fill-color: $bluetypo;
            -khtml-webkit-text-fill-color: $bluetypo;
            -webkit-text-fill-color: $bluetypo !important;
          }
        }
      }
    }
  }
}
