/****** IMPORT PARTS SASS ********/
@import "variables";
@import "editor-style";
@import "animation";
@import "pages/page-skills";
@import "pages/page-home";
@import "pages/page-law";
@import "pages/page-news";
@import "pages/page-flexible";
@import "pages/responsive";
/************************** GENERIC  ****************************************/

* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
}
a[href="#"]{pointer-events: none ;}
.menu-basic a[href="#"]{pointer-events: initial ;}

a {
  text-decoration: none;
  color: $bluetypo;
}

body{
  font-family: $font-notoSans;
  font-weight: 100;
  font-style: normal;
  color :$bluetypo ;
  font-size : 15px;
}

strong {font-weight : 600 ;}

ul {
  list-style: none;
  padding-top: 2%;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

.wrap {
  margin: 0 auto;
  max-width: 1400px;
}

#menu-header a:hover {
  text-decoration: underline;
}

.wrap::after {
  display: block;
  clear: both;
  content: " ";
}

a, a:hover, header .search input[type="text"], header .search:hover input[type="text"], #menu-menu-principal li a::after, #menu-menu-principal li.current-menu-item a::after, #menu-menu-principal li:hover a::after, .flex > .item:hover .item-activity, .flex > .item .item-activity {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.flex {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}

.transition {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.container {
  width: 1600px;
  margin: auto;
  @media all and (max-width: 1635px) {
    width : 98% ;
  }
}

.line {
  display: block;
  bottom: 9px;
  left: 182px;
  position: relative;
  right: 0;
  height: 8%;
  width: 3px;
  @include bg-gradient();
}

.line-blue {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  height: 3px;
  width: 100%;
  @include bg-gradient();
}

.line-vertical {
  position: absolute;
  display: block;
  left: 50%;
  right: 0;
  height: 70px;
  width: 3px;
  @include bg-gradient();
  @media screen and(max-width: 600px){
	display : none ;
  }
}

.line-grey {
  position: absolute;
  display: block;
  right: 100%;
  width: 75px;
  height: 3px;
  background: $greyclear;
  margin-top: 10px;
}

.line-grey-bis {
  position: absolute;
  display: block;
  left: 100%;
  width: 75px;
  height: 3px;
  background: $greyclear;
  margin-top: -10px;
}
.text-align-center{
  text-align: center;
}
.wp-block-spacer {
  height: 50px !important;
}
section {
  padding-bottom: 1%;
}

@media all {

  /*************************************** HEADER ****************************************************/
  header {
    left: 0;
    position: relative;
    right: 0;
    z-index: 30;
    top: 5%;
    width: 100%;
    margin: auto;
    padding: 20px 5%;
	.headerright {margin-left : auto ;}
  }


  header {
    .navbar-wide-container {
      position: relative;

      .flex {
        align-items: center;
		justify-content: flex-end;
		@media all and (max-width: 1000px) {
		  display : none ;
		}
      }
    }
  }


  header {
    .infos-header {
      margin-left: auto;
      align-items: center;
      max-height: 50px;


      p {
        color: $grey;
        font-family: $font-notoSans;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
      }

      .num {
        font-family: $font-notoSans;
        font-weight: 600;
        font-style: normal;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 15px;
        @include bg-gradient();
      }

      .call-back {
        background-color: $blue;
        padding: 15px;
        border-radius: 5px;
        a{
          color: $white;
          font-family: $font-notoSans;
          font-weight: 600;
          font-style: normal;
          cursor: pointer;
        }
      }
    }
	@media all and (max-width: 1450px) {
	  padding : 20px;
	}
  }

  
  /*** MENU ***/
  .overlay-menu.pn {
    margin-left: auto;
    margin-top: -30px;
  }

  #menu-menu-principal{
    li{
      ul{
        li{
        &:hover{
          margin-left: 0;
          a {
            @include bg-gradient();
            color: $white;
            content: '';
            width: 100%;
          }
        }
        }
      }
    }
  }

  #menu-menu-principal {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;

    li {
      background: none;
      padding: 0;

      a {
        color: $blue;
        font-family: $font-effra;
        font-weight: 500;
        font-style: normal;
        text-transform: uppercase;
        font-size: 16px;
        padding: 10px;
        display: inline-block;
        position: relative;
      }
      + li {
        margin-left : 30px;
		@media all and (max-width: 1450px) {
		  margin-left : 15px;
		}
      }
    }
  }

  #menu-menu-principal {
    li.current-menu-item {
      a {
        content: '';
        top: 0;
        left: 0;
        padding-left: 10px;
        padding-right: 10px;
        color: $bluetypo;
      }
    }

    ul.sub-menu {
      a {
        color: $bluetypo;
        background-image: none;
      }
    }
  }

  nav > ul > li {
    display: inline;
    padding: 0 7px;
  }

  nav ul li ul li {
    display: block;
    padding: 0 7px;
  }

  nav li + li {
    border-left: 1px solid;
  }

  /******** RESPONSIVE *******/
  .menu-trigger {
    width: 35px;
    height: 42px;
    margin: 0;
    position: absolute;
    cursor: pointer;
    right : 0 ;
	top: 60px;
    display : none ;
    @media all and (max-width: 1375px) {
	  display : block ;
    }
	@media all and (max-width: 1000px) {
	  top: 35px;
	}
    span {
      display: block;
      background: $blue;
      border-radius: 0;
    }
    #hamburger {
      position: absolute;
	  z-index: 99;
      height: 100%;
      width: 100%;
      span {
        width: 35px;
        height: 3px;
        position: relative;
        top: 0;
        left: 0;
        /*width: 0%;*/
        margin: 7px 0;
        &:nth-child(1) {
          transition-delay: .5s;
        }
        &:nth-child(2) {
        transition-delay: .625s;
        }
        &:nth-child(3) {
          transition-delay: .75s;
        }
      }
    }
    #cross {
      position: fixed;
      height: 35px;
	  z-index: 99999;
      width: 31px;
      transform: rotate(45deg);
      right: 60px;
      span{
        &:nth-child(1) {
          height: 0%;
          width: 3px;
          position: absolute;
          top: 0%;
          left: 15px;
          transition-delay: 0s;
          background: #fff;
        }
        &:nth-child(2) {
          width: 0%;
          height: 3px;
          position: absolute;
          left: 0;
          top: 15px;
          transition-delay: .25s;
          background: #fff;
        }
      }
    }
  }


  .menu-trigger.active
  {
    #hamburger span {
      &:nth-child(1) {
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transition-delay: .125s;
      }
      &:nth-child(3) {
        transition-delay: .25s;
      }
    }
    #cross span
    {
      &:nth-child(1) {
        height: 35px;
        transition-delay: .625s;
      }
      &:nth-child(2) {
        width: 35px;
        transition-delay: .375s;
        top: 16px;
        left: -1px;
      }
    }
  }
  .menu-trigger.active {top : 0 ;display : block ;}

  .menu-basic {
    position: fixed;
    right: 0;
    top: 0;
    max-width: 500px;
	width : 500px;
    z-index: 999;
    height: 100%;
    background-color: $blue;
    display: block;
    transform: translateX(100%);
    transition: all 0.8s cubic-bezier(0.99, 0.01, 0.02, 0.99);
    padding: 40px 30px;
	.menu-menu-principal-container {
      margin: auto;
	  height : 100% ;
	  overflow-y :scroll ;
       > ul.menu {
        display: inline-block;
        padding-left: 0;
        width: 100%;
        > li {
          display: block;
          margin: 0;
          padding: 0 15px;
		  background :none ;
          > a, > span {
            font-size: 21px;
            color: #fff;
          }
          > a {
            position: relative;
            display: inline-block;
            overflow: hidden;
            /*font: normal 18px 'Gotham-Bold';*/
            font: 700 18px "effra",sans-serif;
            padding: 10px 0 10px;
            color: #fff;
            text-transform: uppercase;
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #fff;
              transform: translate3d(-100%, 0, 0) translate3d(-1px, 0, 0);
              transition: transform 0.7s;
              transition-timing-function: cubic-bezier(.7, 0, .3, 1);
            }

          }
          > a:hover
          {
            &::before {
              transform: translate3d(100%, 0, 0) translate3d(1px, 0, 0);
            }
          }
		  ul{
			padding : 0 ;
			margin : 5px 0 15px ;
		  }
		  > ul {
			a {color :$white;}
			> li {
			  padding: 5px 0;

			  > a{
				text-transform : uppercase ;
			  }
			  ul li {
				background: url(../ipsofacto/images/picto-li-white.png) no-repeat top 9px left;
				background-size: 9px;
				padding-left : 20px;
				+ li {
				  margin-top :3px;
				}
			  }
				> ul {
				  margin-top: 20px;
				  margin-bottom: 20px;
				  display: block !important;
					> li > ul {
					  display: block !important;
					}
				}
			}
		  }
		  + li{margin-top : 20px;}
		  > ul.sub-menu {display : none ;}
		  > ul.sub-menu.subopen {display : block ;}
        }
      }
    }
	::-webkit-scrollbar {
	  width: 10px;
	}

	::-webkit-scrollbar-track {
	  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	::-webkit-scrollbar-thumb {
	  background-color: $white;
	  outline: none;
	}

	@media all and (max-width: 550px) {
	  width : 98% ;
	  max-width: 98%;
	}
  }

  .menu-basic.open {
    transform: translateX(0);
  }

  .menu-basic .headmenu {
    margin: 100px 20px 49px;
    text-align: center;
  }
  /************************** FIL ARIANE ****************************/

  #breadcrumbs {
    padding: 80px 0 0;
    font-size: 11px;
    text-align: center;
    margin-bottom: 30px;
    span {
      color: $grey;

      a, .breadcrumb_last {
        font-size: 13px;
        color: $grey;
        font-family: $font-notoSans;
        font-weight: 400;
        text-transform: uppercase;
        -webkit-text-fill-color: $grey;
      }
    }
  }


  /** contenu **/

  article {
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    position :relative ;
  }

  article h1{
    font-family: $font-noto;
    font-size: 40px;
    color: $bluetypo;
    font-weight: 600;
    margin: auto;
    line-height: 50px;
    padding-bottom: 25px;
    padding-top: 15px;
  }

  article h2{
    font-family: $font-noto;
    font-size: 25px;
    color: $bluetypo;
    font-weight: 400;
    margin: auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  article h3{
    font-family: $font-noto;
    font-size: 20px;
    color: $bluetypo;
    font-weight: 400;
    margin: auto;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  article p {
    font-family: $font-effra;
    font-size: 15px;
    color: $bluetypo;
    font-weight: 400;
  }
  article a{
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include bg-gradient();
  }
  article li {
    line-height: 30px;
    padding-left: 3em;
    text-indent: -1em;
    font-family: $font-noto;
    font-size: 15px;
    color: $bluetypo;
  }

  article li li {
    margin-left: 20px;
  }

  article li ul {
    margin-bottom: 5px;
  }

  //article li::before {
  //  content: "\2022\ ";
  //  padding-right: 5px;
  //  color: $bluetypo;
  //}

  .alignright {
    float: right;
    margin-left: 30px;
  }

  .alignleft {
    float: left;
    margin-right: 30px;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
  }

/********* 404 *********************/
  main.error{
    min-height: 500px;

  }
  main.error .wrap{
    display: block;
  }
  main.error .wrap .button-flexible{
    top: 50px;
    margin: auto;
    position: relative;
    max-width: 200px;
    padding: 10px;
    width: 100%;
    text-align: center;
    background-color: $bluefooter;
    a {
      font-family: $font-notoSans;
      color: $white;
    }
  }
  main.error h1{
    text-align: center;
    font-size: 32px;
    font-family: $font-notoSans;
    font-weight: 600;
    padding-bottom: 30px;
    padding-top: 30px;
    line-height: 35px;
    margin-bottom: 50px;
    color: $bluetypo;
  }
  main.error p{
    font-family: $font-notoSans;
    color: $bluetypo;
    font-weight: 300;
    text-align: center;
  }



/******* GENERIC **********************/

  .tel-top{
    position: absolute;
    z-index: 10;
    top : 0 ;
    @include bg-gradient();
    right: 0;
    padding: 15px;
  }

/****************** TARTE AU CITRON *********************/

  #tarteaucitronPersonalize{
    background-color: $bluetypo !important;
  }

  /****************** SEARCH *************************/
    .search {
      .results-research{
        font-family: $font-noto;
        font-size: 25px;
        color: $bluetypo;
        font-weight: 400;
        margin: auto;
        padding-bottom: 15px;
        padding-top: 15px;
      }
      .article_found {
        h1 {
          font-family: $font-noto;
          font-size: 40px;
          color: $bluetypo;
          font-weight: 600;
          margin: auto;
          line-height: 50px;
          padding-bottom: 25px;
          padding-top: 15px;
          text-align: center;
        }

        h2{
          font-family: $font-noto;
          font-size: 25px;
          color: $bluetypo;
          font-weight: 400;
          margin: auto;
          padding-bottom: 15px;
          padding-top: 15px;
        }
        p {
          font-family: $font-effra;
          font-size: 15px;
          color: $bluetypo;
          font-weight: 400;
        }

        a {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include bg-gradient();
        }



        h3 {
          font-family: $font-noto;
          font-size: 20px;
          color: $bluetypo;
          font-weight: 400;
          margin: auto;
          padding-bottom: 15px;
          padding-top: 15px;
        }
      }
    }

/*** Recherche ***/

  body.search .container {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    section.article_found {
      background-color : $greyclear ;
      padding : 25px ;
      flex : auto ; width : 31% ;
      margin : 1% ;
      max-width: 31%;
      @media all and (max-width: 950px) {
        width : 48% ;
        max-width: 48%;
      }

      @media all and (max-width: 700px) {
        width : 98% ;
        max-width: 98%;
      }
    }
  }

/*************************************** PIED DE PAGE ****************************************************/

  /***** Back to Top ********/
  #back-to-top{
    position: fixed;
    text-align: center;
    right: 0;
    cursor:pointer;
    color: $white;
    background-color: $blue;
    z-index: 1999;
    opacity: 1;
    width: 44px;
    bottom: 3.2em;
    padding: 15px;
    transition: opacity .3s linear;
    span{
      display: block;
      //background: url('../ipsofacto/images/arrow-white.png') no-repeat -40px -17px;
      background: url("../ipsofacto/images/arrow-white.png") no-repeat top 5px left;
      width: 11px;
      height: 19px;
      transform: rotate(-90deg);
    }
    &:hover{
      opacity: .6;
    }
  }


  footer {
    background: $bluefooter;
    padding: 50px;

    a {color :$white ;}
    #menu-footer {
      width: calc(100% / 3);
      position: relative;

      #menu-menu-pied-de-page {
        text-align: right;
        a {
          font-weight: 500;
          font-style: normal;
          color: $white;
          font-size: 12px;
          text-transform: uppercase;
        }
      }

      li {
        display: block;
        padding: 0;
  + li {
    margin-top : 5px ;
  }
      }

      #IC {
        float: right;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }



    .contact-footer {
      width: calc(100% / 3);
      text-align: left;

      h2 {
        font-family: $font-noto;
        font-size: 59px;
        color: $white;
        font-weight: 400;
        max-width: 50%;
        opacity: 0.3;
      }

      .num {

        font-weight: 600;
        font-style: normal;
        color : $white;
        font-size: 25px;
      }

      p {
        text-transform: uppercase;
        color: $white;
        font-weight: 400;
        font-size: 10px;
        opacity: 0.7;
              }
    }

    .multi-menu-footer {
      width: calc(100% / 3);

      ul {
        display: flex;
        justify-content: space-around;

        li {
          padding-left: 0;

          a {
            font-size: 10px;
            color: $white;
            font-weight: 400;
            opacity: 0.7;
            padding-top: 5px;
            padding-bottom: 5px;
          }

          h3 {
            font-size: 20px;
            color: $white;
            font-weight: 600;
            padding-bottom: 5px;
          }
        }
      }
      .textwidget{
        color: $white;
        font-size: 13px;
        p, ul li{
          line-height: 25px;
          padding-bottom: 10px;
        }
      }
    }
  }

  @media all and (max-width: 1035px) {
    .wrap {
      width: 98%;
    }
  }

  @media all and (max-width: 835px) {
    .img-blog {
      float: none;
      margin: 0 0 20px;
    }

    .alignright, .alignleft {
      float: none;
      display: block;
      margin: 0 auto 20px;
    }
  }
}
