////////////////////////////////////////////////////////////////
// ANIMATIONS
	@keyframes contentSlideUp{
	  from{
	    transform: translateY(20px);
	    opacity: 0;
	  }
	  to{
	    transform: translateY(0);
	    opacity: 1;
	  }
	}
	// REVEALONSCROLL
	// slideUp
	.revealOnScroll.up{
		transform: translateY(20px);
	    animation-duration: .6s;
	    animation-timing-function: ease-in-out;
	    animation-fill-mode: forwards;
	    opacity: 0;
	}
	.revealOnScroll.up.animated{
		animation-name: contentSlideUp;
		position: relative;
		z-index: 9;
	}

	// fade
	.revealOnScroll.fade{
	    opacity: 0;
		transition: opacity .7s ease-out
	}
	.revealOnScroll.animated.fade{
		opacity: 1;
	}
////////////////////////////////////////////////////////////////
