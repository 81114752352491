.page-skills {
  background-color: $greyclear;

  #breadcrumbs {
    position: relative;
  }

  .content-top {
    padding-top: 7%;
    padding-bottom: 15%;
    @media screen and(max-width: 1335px){
      padding-bottom: 150px;
    }
    @media screen and(max-width: 1024px){
      padding-bottom: 80px;
    }
    @media screen and(max-width: 1205px){
      display: block !important;
      .right-side{
        margin-top: 170px;
        width: 100% !important;
      }
    }
    .left-side {
      width: 40%;

      position: relative;
      margin: auto ;

      .profil {
        position: relative;
        @media (max-width: 1024px)
        {
          max-height : none ;
        }

        img {
          width: auto;
          max-width: 470px;
          max-height: 500px;
          position: relative;
		  border-radius: 5px;
        }

        .rs-left {
          justify-content: flex-end;
          position: absolute;
          top: -40px;
          right: 0;

          a {
            @include bg-gradient();
          }

          img {
            max-width: 20px;
            max-height: 20px;
			border-radius: 0;
          }
        }
      }

      .basic-informations {
        background-color: $white;
        padding: 40px;
		border-radius: 5px;
        //height: 100%;
        //width: 100%;
        //max-height: 200px;
        position: absolute;
        width: 90%;
        right: 0;
        top: 85%;

        p {
          font-size: 15px;
          color: $bluetypo;
          font-family: $font-notoSans;
          font-weight: 500;
          padding: 8px 15px;

          img {
            vertical-align: middle;
            margin-right: 10px;
          }
        }

        strong a, a strong {
          background-color: $blue;
          color: $white;
          padding: 10px;
          font-weight: 600;
          font-family: $font-notoSans;
          display : inline-block;
          text-align :center ;
          margin-top : 10px;
        }

        .rs-left a {margin-top : 0px;    padding: 10px;}
      }
    }

    .right-side {
      width: 60%;
      @media (max-width: 1024px)
      {
        margin-top : 50px !important ;
      }
      .main-informations {
        padding: 50px;
        padding-bottom: 25px;
        max-width: 90%;
        padding-top: 25px;

        h1 {
          font-size: 59px;
          font-family: $font-noto;
          color: $bluetypo;
          font-weight: 400;
        }

        h2 {
          font-family: $font-noto;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include bg-gradient();
          padding-bottom: 15px;
          padding-top : 10px;
          display: inline-block;

          img {
            vertical-align: middle;
            margin-right : 10px;
          }
        }

        p {
          font-family: $font-notoSans;
          font-weight: 300;
          font-size: 14px;
          color: $bluetypo;
          line-height: 30px;
          /*text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 90px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;*/
        }
      }

      .subtitle-specialties {
        padding: 50px;
        padding-top: 0;
        padding-bottom: 30px;
        max-width: 80%;

        p {
          font-family: $font-notoSans;
          font-weight: 300;
          font-size: 14px;
          color: $bluetypo;
          line-height: 30px;
          /*text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 90px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;*/
        }

      }

      .block-resume-top {
        padding-bottom: 10px;
        padding-left: 50px;
        cursor: pointer;
		@media screen and(max-width: 1205px){
		  padding-left: 10px;
		}
        .content-inside-resume {
          font-family: $font-notoSans;
          font-weight: 400;
          font-size: 15px;
          color: $bluetypo;
        span + span {margin-top : 10px;}
          .picto-law {
            img {
              width: 30px;
              margin-right: 15px;
            }
          }

          .text-law {
            &:hover {
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              @include bg-gradient();
            }
          }

          .picto-arrow {
            img {
              margin-left: 15px;
            }

          }

          img {
            vertical-align: middle;
          }

        }
      }
    }
  }

  .content-middle {
    justify-content: space-between;
    padding-bottom: 5%;
    padding-top: 80px;
    position: relative;
    .content-expertise-domain {
      width: 70%;
      background-color: $white;
      height: 100%;
      padding-bottom: 50px;
	  border-radius: 5px;

      .title-expertise {

        padding-bottom: 3%;
        position :absolute;
        left : 0; top : 0 ;

       h1, h2, h3 {
          font-family: $font-noto;
          font-weight: 300;
          color: $bluetypo;
        }
        > h2{
          display: inline-block;
          padding-right: 80px;
          position: relative;
          font-size : 27px ;
        }

        .line-blue {
          width: 50px;
          position: absolute;
          top: 55%;
          right: 0;
          left: auto;
          transform: translateY(-50%);
        }
      }

      .block-expertise-domain {
        padding: 50px;
        padding-bottom: 0;
        img {
          padding-bottom: 10px;
        }

        h1,h2,h3 {
          font-family: $font-notoSans;
          color: $bluetypo;
          font-weight: 400;
          padding-bottom: 20px;

          img {
            padding-bottom: 0;
          }
        }

        h4 {
          font-family: $font-noto;
          font-weight: 400;
          font-size: 14px;
          color: $bluetypo;
        }


        ul {
          padding: 20px;
          list-style: initial;
          font-size: 15px;

          li {
            font-family: $font-notoSans;
            color: $bluetypo;
            line-height: 25px;
            list-style: initial;
            background: none;
            padding-left : 0 ;
            ul{
              padding-top: 0;
              li {
                list-style: circle;
              }
            }
          }
        }
      }
    }

    .sidebar-skills {
      width: 25%;

      ul li {
        background: url(../ipsofacto/images/picto-li-white.png) no-repeat top 5px left;
      }

      h3, h2 {
        font-family: $font-noto;
        font-weight: 300;
        padding-bottom: 20px;
      }

      p {
        font-family: $font-notoSans;
        font-weight: 300;
        padding-bottom: 10px;
        font-size: 14px;
      }

      img {
        margin-right: 10px;
      }

      .block-strong {
        @include bg-gradient-reverse();
        color: $white;
        padding: 30px;
        margin-bottom: 50px;
		border-radius: 5px;
      }

      .block-course {
        background-color: $bluefooter;
        color: $white;
        padding: 30px;
        margin-bottom: 90px;
		border-radius: 5px;
      }

      .block-last-articles {
        h2 {
          font-family: $font-noto;
          font-weight: 300;
          color: $bluetypo;
          text-align: center;
          padding-bottom: 5%;
        }

        .content-articles {
          max-width: 360px;
          margin: auto;

          .img-bloc-plus {
			max-height: 240px;
			overflow: hidden ;
            img {

              /*width: 100%;
              height: 100%;*/
            }
          }

          .summary-news {
            background-color: $white;
            padding: 20px;
            position: relative;
			display: inline-block;
			width: 100%;
            .text-bloc-plus {
              width: 100%;
              padding-bottom: 2%;

              a {
                color: $bluetypo;
                font-family: $font-noto;
                font-size: 20px;
                font-weight: 600;
                -webkit-text-fill-color: $bluetypo;
                line-height: initial;
              }

              span {
                font-family: $font-notoSans;
                font-size: 14px;
                color: $bluetypo;
                font-weight: 400;
                //padding-top: 10px;
                display: inline-flex;

                p {
                  color: $bluetypo;
                  font-weight: 600;
                  margin-left: 5px;
                }
              }

              img {
                float: right;
                padding-right: 20px;
              }
            }

            .more-informations-news {
              img {
                position: relative;
                left: 85%;
                bottom: 25px;
              }
            }
            .cat-more-infos{
              position: absolute;
              top: -39px;
              left: 0;
              span{
                font-family: $font-notoSans;
                display: inline-block;
                color: $white !important;
              }
              .cat-news {
                @include bg-gradient();
                color: $white;
                padding: 10px;
                margin-left: -4px;
                span.pipe-cat{
                  &:last-child{
                    display: none;
                  }
                }
              }
              .date-color{
                background-color: $white;
                padding: 0;
                .cat-date {
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  padding: 10px;
                  @include bg-gradient();
                }
              }
            }
          }
        }
      }
      .upw-posts article{
        border: none;
      }
    }
  }
}
