/*************************************** ACCUEIL ****************************************************/
.home-page {
  .content-top {
    position: relative;
    .img-back {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;

      img {
        max-height: 470px;
        width: 100%;
        object-fit: cover;
      }

      &::before {
        content: ' ';
        background-color: rgba(18, 43, 103, .7);
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 470px;
        z-index: 0;
      }
    }
  }

  /*.content-top .img-back img::before{*/
  /*content: '';*/
  /*background-color: rgba(0, 0, 0, 0.5);*/
  /*top: 0;*/
  /*left: 0;*/
  /*height: 100%;*/
  /*width: 100%;*/
  /*position: absolute;*/
  /*}*/
  .content-top {
    .text-titles {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translateY(-45%);
      line-height: 40px;
    }
  }

  .content-top {
    .button-contact {
      position: absolute;
      top: 65%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translateY(-65%);
      margin: auto;
      @include bg-gradient();
	  border-radius: 5px ;
    }

    .social-network-top {
      .rs-top {
        position: absolute;
        z-index: 1;
        top: 45%;
        right: 0;
        padding-right: 20px;

        img {
		  max-height: 20px;
		  max-width: none;
		  min-height: auto;
		  width: auto;
		  display : block;
		  margin: 10px auto;
        }
      }
    }
  }

  .text-titles {
    h1 {
      font-family: $font-notoSans;
      font-style: normal;
      color: $white;
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 400;
      strong{
        font-weight: 700;
      }
    }
  }

  .text-titles {
    h2 {
      font-family: $font-notoSans;
      font-weight: 400;
      font-style: normal;
      color: $white;
      font-size: 28px;
      text-transform: uppercase;
    }
	h3 {
	  font-size: 20px;
	  color: $white;
	  margin-bottom: 5px;
	}
  }

  .content-top {
    .button-contact {
      max-width: 180px;
      padding: 10px;
      text-align: center;
    }
  }

  .content-top {
    .button-contact {
      a {
        font-family: $font-notoSans;
        font-weight: 400;
        font-style: normal;
        color: $white;
        padding-left: 5px;
      }
    }
  }

  .title-areas {
    text-align: center;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $greyclear;

    h2 {
      font-family: $font-notoSans;
      font-size: 25px;
      font-weight: 400;
      color: $bluetypo;
      line-height: 30px;
    }
  }
  .srch-home{
    background-color: $greyclear;
    display: block;
    form{
      .container {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        position: relative;
        input {
          background: $white;
          border: 0 none;
          box-sizing: border-box;
          color: $bluetypo;
          display: inline-block;
          font-size: 1em;
          font-family: $font-notoSans;
          letter-spacing: 0.02em;
          line-height: 32px;
          overflow: hidden;
          padding: 0 32px 0 10px;
          vertical-align: top;
          width: 50%;
          -o-transition: all .5s;
          -moz-transition: all .5s;
          -webkit-transition: all .5s;
          -o-transition: all .5s;
          transition: all .5s;
          height: 60px;
          margin-left: auto;
        }
        button {
         background : url(../ipsofacto/images/loup.jpg) no-repeat center ;
		  background-size: 60px;
          border: medium none;
          color: $white;
          cursor: pointer;
          display: inline-block;
          font-size: 1.3em;
          line-height: 32px;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          vertical-align: top;
          width: 60px;
          height: 60px;
          margin-right: auto;
		  border-radius: 5px;

        }
      }
      ::-webkit-input-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }

      ::-moz-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }

      :-ms-input-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }

      :-moz-placeholder {
        color: $bluetypo !important;
        font-family: $font-notoSans;
      }
    }
  }

  .content-areas {
    background-color: $greyclear;
    padding: 20px 40px 20px 40px;

    .item {
      flex : auto ;
      width: 24%;
      position: relative;
      margin: 0.5%;
      border-radius : 5px;
      background-color: $white;
    }

    .item {
      .item-areas {

      }
    }

    .content-inside-areas {
      //top: 50%;
      padding: 30px 30px 60px;
      position :relative ;
      //transform: translateY(-50%);
      width: 100%;
      z-index: 10;
      height: 100%;

      .title-block {
        font-family: $font-effra;
        font-weight: 500;
        font-size: 25px;
        color: $bluetypo;
        text-align: center;
        padding-bottom: 20px;
        max-width: 75%;
        margin: auto;
        line-height: 25px;
      }

      img {
        display: block;
        margin: auto;
        padding-bottom: 20px;
      }

      .picto-more {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
      }
    }

    .item-areas {
      .description-areas {
        opacity: 0;
        color: $white;
        font-family: $font-effra;
        font-weight: 500;
        font-style: normal;
        position: absolute;
        top: 50%;
        padding: 30px 30px 50px;
        transform: translateY(-50%);
        width: 100%;
        z-index: 10;
        line-height: 18px;
        .see-all{
          text-transform: uppercase;
          @include bg-gradient();
          font-family: $font-effra;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 600;
          display : inline-block;
          position :relative ;
          padding-bottom: 10px;
          margin-top : 20px;
          .line{
            position: absolute;
            transform: rotate(180deg);
            left: 0px;
            width: 100% ;
            height: 2px;
            bottom : 0 ;
          }
        }
        p{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          line-height: 20px;
          max-height: 100px;
        }
      }
    }

    .picto-more-activity {
      max-width: 20px;
      width: auto;
      margin: auto;
      padding-top: 5px;
      position :absolute;
      bottom : 0 ; right : 0 ; left : 0;
    }

    .item-areas:hover {
      .content-inside-areas {
        opacity: 0;
      }
    }

    .item-areas:hover {
      .description-areas {
        opacity: 1;
      }
    }

    .item:hover {
      .item-areas {
        background-color: $blue;
        height: 100%;
        width: 100%;
        z-index: 90;
        border-radius: 5px;
      }
    }

    .item {
      .description-areas {
        text-align: left;
      }
    }
  }

  .office-values {
    padding  : 100px 0 ;
    .content-lr {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      overflow: hidden;

      .text-lr {
        margin: auto;

        .text-inside {
          height: 100%;
          padding: 50px 70px 200px 150px;
          position: relative;
          max-width: 100%;
          width: 100%;
          @media screen and (max-width: 1024px){
            padding: 50px 50px 200px 130px;
          }

          .button-lawyers {
            background-color: $blue;
            display: inline-block;
			border-radius: 5px;

            padding: 15px;
            //margin-left: 25%;
            margin: auto;
            text-align: center;
            margin-top: 40px;

            a {
              color: $white;
              font-family: $font-noto;
              font-size: 16px;
              font-weight: 400;
            }
          }

          h2 {
            font-family: $font-noto;
            font-size: 59px;
            color: $bluetypo;
            font-weight: 500;
            padding-bottom: 5%;

            line-height: 50px;
          }

          p, ul li {
            line-height: 27px;
            color: $bluetypo;
            font-weight: 400;

          }

         h3 {
            font-size: 25px;
            margin-bottom: 15px;
          }

          .contact-fond_lawyers, .contact-fond_office {
            font-family: $font-noto;
            font-style: normal;
            font-weight: 600;
            font-size: 200px;
            color: #E5E5E6;
            display: block;
            text-align: center;
            opacity: 0.3;
            position: absolute;
            z-index: -2;
            @media screen and (max-width: 1000px){
              font-size: 150px;
            }

            @media screen and (max-width: 750px){
              font-size: 100px;
            }
          }

          .contact-fond_lawyers {
            left: 0!important;
            bottom: 30px;

          }

          .contact-fond_office {
            bottom : 30px;
          }

          .vertical-text-1, .vertical-text-2 {
            @include bg-gradient();
            font-family: $font-noto;
            font-weight: 600;
            font-style: normal;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 18px;
            transform: rotate(-90deg);
            position: absolute;
            @media screen and (max-width: 767px){
              display :  none ;
            }

            .line {
              width: 30px;
              height: 2px;
              @include bg-gradient();
            }
          }

          .vertical-text-1 {
            bottom: 50%;
            left: 0;
          }

          .vertical-text-2 {
            right: 0;
            top: 50%;
          }
        }
      }

      .img-lr, .text-lr {
        width: 60%;
        align-items: center;
        display: flex;
      }


      .img-lr {
        width: 40%;
        img {
          height: 100%;
          object-fit: cover;
          max-width: 470px;
          max-height: 500px;
          width: 100%;
          border-radius : 5px;
         // padding: 30px;
        }
      }
    }

    .content-lr:nth-child(even) {
      .img-lr {
        order: 0;
        img {
          margin-left: auto;
          border-radius : 5px;
        }
      }

      .text-lr {
        order: 1;
        .text-inside {
          padding: 50px 150px 200px 70px;
          @media screen and (max-width: 1024px){
            padding: 50px 130px 200px 50px;
          }
        }
      }
    }
  }

  .block-news {
    background-color: $greyclear;
    padding-top: 5%;
    padding-bottom: 7%;

    .title-news {
      font-size: 38px;
      color: $bluetypo;
      text-align: center;
      font-family: $font-noto;
    }

    .social-network {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 2%;
      img {
        margin: 5px;
      }
    }

    .wrap {
      justify-content: center;
    }

    .content-articles {
      width: 100%;
      .img-bloc-plus {
        position: relative;
		max-height: 240px;
		overflow: hidden;
        img {
          /*max-height: 240px;
          width: 100%;
          height: 100%;*/
          border-radius: 5px 5px 0 0;
        }
      }
      .summary-news {
        background-color: $white;
        padding: 20px;
        position: relative;
        padding-top: 0;
        border-radius: 0 0 5px 5px;

        .text-bloc-plus {
          width: 100%;

          a {
            color: $bluetypo !important;
            font-family: $font-noto;
            font-weight: 600;
			text-overflow: ellipsis;
			display: -webkit-box;
			max-height: 75px;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			padding : 0 ; margin : 10px 0  5px;
			font-size: 18px;
          }

          span {
            font-family: $font-notoSans;
            font-size: 14px;
            color: $bluetypo;
            font-weight: 400;
            //padding-top: 10px;
            display: inline-flex;
            //padding-left:15px;

            p {
              color: $bluetypo;
              font-weight: 600;
              margin-left: 5px;
            }
          }

          img {
            float: right;
            padding-right: 20px;
          }
        }
        h3.text-bloc-plus{
          a{
            -webkit-text-fill-color: $bluetypo;
			background :transparent ;
          }
        }
        .more-informations-news{
          img{
            position: relative;
            left: 85%;
            bottom: 25px;
          }
        }
        .cat-more-infos{
          position: absolute;
          top: -39px;
          left: 0;
          span{
            font-family: $font-notoSans;
            display: inline-block;
            color: $white;
          }
          .cat-news {
            @include bg-gradient();
            color: $white;
            padding: 10px;
            margin-left: -4px;
            span.pipe-cat{
              &:last-child{
                display: none;
              }
            }
          }
          .date-color{
            background-color: $white;
            padding: 0;
            .cat-date {
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              padding: 10px;
              @include bg-gradient();
            }
          }
        }
      }
      article{
        margin-bottom: 0;
        max-width: 360px;
        width: 100%;
        box-shadow: 0 15px 40px -10px rgba(0,0,0,0.35);
        -webkit-box-shadow: 0 15px 40px -10px rgba(0,0,0,0.35);
        -moz-box-shadow: 0 15px 4px -10px rgba(0,0,0,0.35);
        padding-top: 0;
        padding-bottom: 0;
        margin: auto;
      }
      .flex{
        justify-content: space-evenly;
      }
    }

    .link-news {
      text-align: center;
      padding-top: 3%;
      a {
        @include bg-gradient();
        font-family: $font-noto;
        font-weight: 600;
        font-style: normal;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 23px;
        padding-bottom: 10px;
        position: relative;
      }
    }
  }
}

.home-page + .section-map {
  padding-bottom: 0;
  .content-map {
    position: relative;
    margin-bottom: -5px;

    iframe {
      width: 100%;
      max-height: 325px;
    }
  }
}
