.page-template-page-law {
  #breadcrumbs {
    position: absolute;
    width: 100%;
    z-index: 3;
    //bottom: 50%;
    //transform: translateY(-50%);
  }

  .page-law {
    background-color: $greyclear;

    .content-top-law {
      position: relative;
      .img-back {
        width: 100%;

        img {
          max-height: 470px;
          width: 100%;
          -o-object-fit: cover;
          object-fit: cover;

        }

        &::before {
          content: ' ';
          background-color: rgba(18, 43, 103, .7);
          position: absolute;
          width: 100%;
          height: 100%;
          max-height: 470px;
          z-index: 0;

        }
      }

      .social-network-top {
        .rs-top {
          position: absolute;
          z-index: 1;
          top: 50%;
          right: 0;
          padding-right: 20px;
          transform: translateY(-50%);

          img {
            object-fit: contain;
            width: 30%;
            height: 30%;
            display: flex;
            padding-bottom: 10px;

            &:first-child {
              max-height: 30px;
            }

            &:last-child {
              min-height: 30px;
            }
          }
        }
      }

      .title-law {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translateY(-50%);
        bottom: 0;

        h1 {
          font-size: 50px;
          font-family: $font-noto;
          color: $greyclear;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          text-align: center;
          transform: translateY(-50%);
          bottom: 0;
		  @media screen and(max-width: 800px){
			font-size : 40px;
			top : 40% ;
			width: 100%;
			padding: 0 100px 0 20px;
			line-height: inherit;
		  }
		  @media screen and(max-width: 600px){
			font-size : 32px;
		  }
        }
      }

      .description-law {
        padding-top: 50px;
        font-size: 24px;
        font-family: $font-noto;
        color: $bluetypo;
        text-align: center;
        max-width: 40%;
        margin: 0 auto;
        line-height: 30px;
      }
    }

    .content-left, .content-right {
      width: 35%;
      margin: 0 auto;
      padding-top: 5%;
    }



    .block-first-text, .block-our-lawyers, .others-text {
      h2 {
        font-size: 35px;
        line-height: 35px;
        font-family: $font-noto;
        color: $bluetypo;
        font-weight: 300;
        margin-bottom: 2%;
        margin-top: 2%;
      }

      h3{
        font-size: 20px;
        font-family: $font-noto;
        color: $bluetypo;
        font-weight: 300;
        margin-bottom: 2%;
        margin-top: 2%;
      }

      p {
        font-family: $font-notoSans;
        color: $bluetypo;
        font-size: 15px;
        line-height: 23px;
      }

      li {
        font-family: $font-notoSans;
        color: $bluetypo;
        font-size: 15px;
        line-height: 25px;
        list-style-type: none;
        margin :3px 0 ;
      }
    }

    .block-first-text {
      h2 {
        line-height: 45px;
        position: relative;
        margin-left: 15px;
        margin-bottom: 4%;
        margin-top: 4%;

        &:before {
          height: 100%;
          width: 2px;
          @include bg-gradient();
          content: '';
          position: absolute;
          left: -15px;
        }
      }
    }

    .block-our-lawyers {

      max-width: 500px;
      width: 100%;

      max-height: 100%;
      height: 100%;

      .blocrightlaw {
        background-color: $white;
        padding: 40px 0 00px 0;
        position: relative;
        border-radius: 5px;

      h2 {
        text-align: center;
        font-size: 40px;
        line-height: 35px;
      }

      .subtitle-lawyers {
        color: $white;
        font-family: $font-notoSans;
        font-size: 14px;
        padding: 5px 10px 5px 10px;
        @include bg-gradient();
        margin: 0 auto;
        display: table;
        margin-top: 5%;
        position: relative;
      }

      .content-our-lawyers {
        border-bottom: 2px solid $greyclear;
        width: 100%;
        margin: 0 auto;
        padding-top: 15px;
        padding-bottom: 0;

        &:nth-child(1) {
          display: flex;
        }

        &:nth-child(2) {
          display: flex;
        }

        .lawyers {
          max-height: 200px;
          padding: 20px 30px;
		  position :relative ;
          width: 100%;
          align-items: center;

          &:nth-child(even) {
            background: $blue;
            .name {
              color :$white;
            }
          }

          .informations {
            text-align: center;
            padding-right: 90px;
            width: calc(100% - 115px);
            position: relative;

            img {
              margin-top: 2%;
              max-width: 25px;
              max-height: 25px;
            }
            a {
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          img {
            border-radius: 50%;
            width: 115px;
            height: 115px;
            max-height: 100%;
            max-width: 100%;
            object-fit: cover;

          }
		  img.specialist {
			position: absolute;
			top: 10px;
			left: 115px;
			width: 45px;
			height: 45px;
		  }
        }
      }

      .name {
        color: $bluetypo;
        font-family: $font-noto;
        font-size: 18px;
        font-weight: 600;
        width: 50%;
        text-align: center;
        margin: 0 auto;
      }

      .collaboration {
        font-family: $font-noto;
        color: $bluetypo;
        font-size: 20px;
        text-align: center;
        padding-top: 40px;
        border-radius: 0 0 5px 5px;
        padding-bottom: 45px;

        background-color :$white ;


        .name-collab {
          font-size: 18px;
          font-weight: 600;
          padding-top: 10px;
        }
      }
    }
    }

    .others-text {
      padding-bottom: 5%;
      margin-top: 50px;
      display : inline-block ;
      width : 100%;
      .content-text {
        //padding-top: 2%;
        padding-bottom: 2%;
        width: 85%;
        margin: auto;

        h2 {
          position: relative;
          margin-left: 15px;
          line-height: 35px;
          &:before {
            height: 100%;
            width: 2px;
            @include bg-gradient();
            content: '';
            position: absolute;
            left: -15px;
          }
        }

        ul {
          display: flex;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;

          li {
            line-height: 30px;
            -webkit-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            width: 49%;
            max-width: 49%;
            padding-left: 40px;
          }
        }
      }
    }
  }
}
