/********* COLORS *******/

$blue :#0d1c40;
$bluetypo: #132651;
$bluefooter: #12244e;
$white: #FFF;
$grey: #adadad;
$greyclear: #f4f4f4;
$gradient-1:#00d2ff;
$gradient-2: #0045e7;


/******** FONTS ***********/

$font-notoSans: 'Noto Sans', sans-serif;
$font-noto: 'Noto Serif', serif;
$font-effra: 'effra', sans-serif;

/****** Break Points *******/

$desk-M: "(max-width: 1375px)";
$desk-S: "(max-width: 1024px)";
$phab-L :"(min-width:768px) and (max-width:1023px)";
$phab: "(max-width: 767px)";
$mobil: "(max-width: 540px)";

/******* GRADIENT ********/

@mixin bg-gradient(){
  background-image: linear-gradient(to left, $gradient-1, $gradient-2);
  background-image: -webkit-linear-gradient(to left, $gradient-1, $gradient-2);
  background-image:    -moz-linear-gradient(to left, $gradient-1, $gradient-2);
  background-image:      -o-linear-gradient(to left, $gradient-1, $gradient-2);
}

@mixin bg-gradient-reverse(){
  background-image: linear-gradient(to right, $gradient-1, $gradient-2);
  background-image: -webkit-linear-gradient(to left, $gradient-1, $gradient-2);
  background-image:    -moz-linear-gradient(to left, $gradient-1, $gradient-2);
  background-image:      -o-linear-gradient(to left, $gradient-1, $gradient-2);
}