@charset "utf-8";

    ul {
      list-style: none;
      li {
        list-style-type: none;
        background: url("../ipsofacto/images/picto-li.png") no-repeat top 5px left;
        padding: 3px 0 3px 30px;
        background-size : 16px;
      }
    }

