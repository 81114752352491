.listing-blog {
  //padding-bottom: 100px;
  background-color: $greyclear;

  #breadcrumbs {
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .img-back {
    width: 100%;

    img {
      max-height: 470px;
      width: 100%;
      object-fit: cover;
    }

    &::before {
      content: ' ';
      background-color: rgba(18, 43, 103, .7);
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 470px;
      z-index: 0;
    }

    .social-network-top {
      .rs-top {
        position: absolute;
        z-index: 1;
        top: 52%;
        right: 0;

        img {
          object-fit: contain;
          width: 30%;
          height: 30%;
          display: flex;
          padding-bottom: 10px;

          &:first-child {
            max-height: 30px;
          }

          &:last-child {
            min-height: 30px;
          }
        }
      }
    }
  }

  .text-titles {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);

    h1 {
      font-size: 50px;
      color: $greyclear;
      font-family: $font-noto;
    }
  }

  .liste-categorie {
    text-align: center;
    position: absolute;
    top: 330px;
    margin: auto;
    left: 0;
    right: 0;
    border-top: 2px solid $greyclear;
    border-bottom: 2px solid $greyclear;
    width: 70%;

    .searchandfilter {
      ul {padding-top : 0 ;}
      > ul {
        padding : 10px 0;

        li {
          display: inline-block;
          padding: 0 25px;
          font-size: 19px;
          text-transform: uppercase;
          font-weight: bold;
          text-indent: 0;
          background: none;

          &::before {
            display: none;
          }

          .last-child {
            display: none;
          }
        }

        li.sf-field-category {
          li {
            border-right: 2px solid $greyclear;
            &:first-child{
              display: none;
            }
            &:last-child {
              border-right: none;
            }
          }
        }


        label {
          position: relative;
          cursor: pointer;
          padding: 0;
          color: $greyclear;
          text-transform: uppercase;
          font-family: $font-notoSans;
          font-weight: 600;
          font-size: 15px;
        }

        input[type=radio] {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          opacity: 0;
        }
      }
    }
  }

  .flex {
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 2%;
  }

  .pagination {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  article {
    height: 100%;
    padding-top: 0;
    .search-filter-results{
      height: 100%;
      min-height: 600px;
      .masonry {
        height: 100%;
        .grid-item {
          &:first-child {
            width: 760px !important;
          }
        }
      }
    }
  }
  .content-top{
    position: relative;
    min-height: 500px;
  }

}


.liste-categorie li label:hover, .liste-categorie li.sf-option-active input[type="radio"] + label, .liste-categorie li input[checked=checked] + label {
  color: $greyclear;
  text-transform: uppercase;
  font-family: $font-notoSans;
  font-weight: 600;
  font-size: 15px;
}

.liste-categorie li label:hover::after, .liste-categorie li.sf-option-active input[type="radio"] + label::after, .liste-categorie li input[checked=checked] + label::after {
  width: 20px;
}
.grid-sizer{
  width: 31%;
  margin: 0 1%;
}

.grid-item {
  width: 31%;
  margin: 0 1%;
  &:first-child{
    width: 700px !important;
  }
}

.grid-item {
  margin-bottom: 15px;
  background-color: $white;
  box-shadow: 0 15px 40px -10px rgba(0,0,0,0.35);
  -webkit-box-shadow: 0 15px 40px -10px rgba(0,0,0,0.35);
  -moz-box-shadow: 0 15px 4px -10px rgba(0,0,0,0.35);
  min-height: 400px;
  border-radius: 5px;
  overflow: hidden;
  height: auto;
  a img {
    width: 100%;
  }
}

.gutter-sizer {
  width: 2%;
}

.img-news {
  position: relative;
  > a > p > img{
height : 270px; object-fit: cover ;
  }

  .cat-more-infos {
    position: absolute;
    bottom: 0;
    /*display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;*/
	display: block;
    span {
      display: inline-block;
      font-family: $font-noto;
    }

    .cat-news {
      @include bg-gradient();

      color: $white;
      padding: 10px;
      margin-left: 0;
	  display : block;
      span.pipe-cat{
        &:last-child{
          display: none;
        }
      }
    }

    .date-color {
      background-color: $white;
      flex: 1;
      .cat-date {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding: 10px;
        @include bg-gradient();
      }
    }
  }

}

.content-blog {
  padding: 25px;
  background-color: $white;

  h2 {
    color: $bluetypo !important;
    font-family: $font-noto;
    font-size: 18px;
    font-weight: 600;
	text-overflow: ellipsis;
	display: -webkit-box;
	max-height: 75px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	padding : 0 ; margin : 15px 0 ;
	height: 75px;

    a {
      color: $bluetypo;
      -webkit-text-fill-color:transparent;
      background-image: -webkit-gradient(linear,right top,left top,from($bluetypo),to($bluetypo));
      -webkit-background-clip: text;
    }
  }

  .author {
    font-family: $font-notoSans;
    font-size: 14px;
    color: $bluetypo;
    font-weight: 400;
    padding-top: 10px;

    a {
      color: $bluetypo;
      font-weight: 600;
      -webkit-text-fill-color: $bluetypo;
    }
  }

  .more {
    img {
      width: auto;
      position: relative;
      left: 85%;
      bottom: 25px;
    }
  }
}

//.content-blog p::after {
//  content: "";
//  width: 45px;
//  background: url(images/picto.png) no-repeat bottom center;
//  position: absolute;
//  bottom: -33px;
//  left: 0;
//  height: 33px;
//}


.listing-blog .pagination .wp-pagenavi span.current, .listing-blog .pagination .wp-pagenavi a:hover {
  background-color: #202833;
  color: #FFF;
  border: 1px solid #202833;
}

.listing-blog .pagination .wp-pagenavi a, .listing-blog .pagination .wp-pagenavi span {
  padding: 8px 5px;
  width: 40px;
  display: inline-block;
}
